import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Attendee } from "@features/attendee/models/attendee.model";


type CreateOrUpdateAttendeeFormControls = {
  lastname: AbstractControl;
  firstname: AbstractControl;
  company?: AbstractControl;
}

@Component({
  selector: 'app-create-or-update-attendee-overview-dialog',
  templateUrl: './create-or-update-attendee-overview-dialog.component.html',
  styleUrls: ['./create-or-update-attendee-overview-dialog.component.scss']
})
export class CreateOrUpdateAttendeeOverviewDialogComponent implements OnInit {

  form: FormGroup<CreateOrUpdateAttendeeFormControls> = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    lastname: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    company: new FormControl('', [Validators.minLength(2), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
  } as CreateOrUpdateAttendeeFormControls);

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { attendee: Partial<Attendee> | null },
    private readonly bottomSheetRef: MatBottomSheetRef<CreateOrUpdateAttendeeOverviewDialogComponent>
  ) {
  }

  ngOnInit() {
    if (this.data?.attendee) {
      this.form.patchValue(this.data.attendee);
    }
  }

  // @ts-ignore
  dismiss(formValue: typeof this.form.value | null): void {
    this.form.valid && this.bottomSheetRef.dismiss(
      Object.keys(formValue).reduce((acc, key) => {
        acc[key] = formValue[key].trim()
        return acc;
      }, {})
    );
  }
}
