export enum SnackEnum {
  CANNOT_ACCESS_RESOURCE = 'Vous n\'êtes pas autorisé à accéder à cette ressource.',
  AN_ERROR_OCCURRED = 'Une erreur est survenue',
  INCORRECT_EMAIL_OR_PASSWORD = 'Email ou mot de passe incorrect',
  LOGGED_OUT = 'Vous avez été déconnecté',
  ATTENDEE_CREATED = 'Ajouté avec succès !',
  ACCESS_LINK_GENERATED = 'Lien d\'accès généré avec succès !',
  GENERIC_ERROR = 'Oops ! Une erreur est survenue 👀',
  TOKEN_EXPIRED = 'Votre session a expiré, veuillez renouveler votre accès',
  NOTIFICATION_SENT = 'Notification envoyée avec succès !',
  EVENT_UPDATED = 'Mis à jour avec succès !',
}
