<h1 mat-dialog-title>Configurer les notifications Slack</h1>
<div mat-dialog-content>

  <div style="margin: 1rem 0">
    <p>Afin de notifier l'arrivée des invités, merci de renseigner <b>le webhook</b> vers le canal de notification.</p>
    <p>L'information peut être fournie par votre administrateur Slack.</p>
  </div>

  <mat-form-field style="width: 100%; margin: 1rem 0;" appearance="fill">
    <mat-label>Webhook Slack</mat-label>
    <input matInput placeholder="Webhook" [(ngModel)]="slackWebhook" pattern="https://hooks.slack.com/services/.*" required>
    <mat-hint align="end">Format attendu : <i>https://hooks.slack.com/services/...</i></mat-hint>
  </mat-form-field>


  <div mat-dialog-actions align="end">
    <button class="btn" color="warn" mat-stroked-button mat-dialog-close>Fermer</button>
    <button class="btn" color="primary" mat-flat-button [mat-dialog-close]="slackWebhook">Confirmer</button>
  </div>
</div>
