import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Attendee } from "@features/attendee/models/attendee.model";
import { EventService } from "@features/event/services/event.service";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AttendeeService {

  constructor(
    private readonly http: HttpClient,
    private readonly eventService: EventService
  ) {
  }

  updateAttendee(attendee: Partial<Attendee>): Observable<any> {
    return this.http.patch<Partial<Attendee>>(`${environment.baseUrl}/attendee/${attendee._id}`, attendee);
  }

  updateAttendeeForStaff(attendee: Partial<Attendee>, token?: string) {
    return this.http.patch<Partial<Attendee>>(`${environment.baseUrl}/attendee/staff/${attendee._id}`, attendee, {
      ...token && { headers: { 'Authorization': `Bearer ${token}` } }
    });
  }
}
