<div class="actions shadow-scoll">
  <button class="action-btn" color="primary" mat-stroked-button (click)="openUploadDialog.emit(true)" disableRipple>
    <mat-icon>upload_file_outline</mat-icon>
    Importer une liste
  </button>
  <button class="action-btn" color="primary" mat-stroked-button (click)="openShareAccessLinkDialog.emit(true)">
    <mat-icon>share_outline</mat-icon>
    Partager l'accès
  </button>
  <button class="action-btn" color="primary" mat-stroked-button (click)="openSlackSettingDialog.emit(true)">
    <mat-icon>notifications</mat-icon>
    Configurer Slack
  </button>

  <button class="action-btn" color="primary" mat-stroked-button (click)="exportAttendeeListDialog.emit(true)">
    <mat-icon>download</mat-icon>
    Exporter invités
  </button>

  <button class="action-btn" color="primary" mat-stroked-button (click)="openChartDialog.emit(true)">
    <mat-icon>area_chart</mat-icon>
    Statistiques
  </button>

</div>
