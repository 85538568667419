import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AttendeeModule } from "@features/attendee/attendee.module";
import { ActionToolbarComponent } from "@features/event/components/action-toolbar/action-toolbar.component";
import { SharedModule } from "@shared/shared.module";

import { EventRoutingModule } from './event-routing.module';
import { EventListViewComponent } from './pages/event-list-view/event-list-view.component';
import { EventViewComponent } from './pages/event-view/event-view.component';
import { EventStaffViewComponent } from './pages/event-staff-view/event-staff-view.component';



@NgModule({
  declarations: [
    EventListViewComponent,
    EventViewComponent,
    ActionToolbarComponent,
    ActionToolbarComponent,
    EventStaffViewComponent
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    HttpClientModule,
    EventRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatToolbarModule,
    SharedModule,
    MatMenuModule,
    AttendeeModule,
    MatListModule,
    FormsModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatTabsModule
  ],
  providers: []
})
export class EventModule { }
