const _debounce = (func: any, delay: number) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export function Debounce(ms: number) {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = _debounce(oldFunc, ms);
    descriptor.value = function () {
      return newFunc.apply(this, arguments);
    };
  };
}
