import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Debounce } from "@core/decorators";
import { SnackService } from "@core/services/snack.service";
import { Attendee } from "@features/attendee/models/attendee.model";
import { AttendeeService } from "@features/attendee/services/attendee.service";
import type { Event as EventModel } from "@features/event/models/event.model";
import { EventService } from "@features/event/services/event.service";
import { makeGenericSimpleChartOptions, mapChartArrivalData, mapCheckoutData } from "@features/event/utils/event-chart.util";
import { ACCEPTED_ATTENDEE_LIST_FILE_TYPES, searchByAttendeeName } from "@features/event/utils/event.util";
import { AttendeeChartsDialogComponent } from "@shared/components/dialogs/attendee-charts-dialog/attendee-charts-dialog.component";
import { CreateOrUpdateAttendeeOverviewDialogComponent } from "@shared/components/dialogs/create-or-update-attendee-overview-dialog/create-or-update-attendee-overview-dialog.component";
import { ImportFileDialogComponent, ImportFileDialogData } from "@shared/components/dialogs/import-file-dialog/import-file-dialog.component";
import { ShareEventAccessLinkDialogComponent } from "@shared/components/dialogs/share-event-access-link-dialog/share-event-access-link-dialog.component";
import { SlackSettingDialogComponent } from "@shared/components/dialogs/slack-setting-dialog/slack-setting-dialog.component";
import { AttendeeListFileImportInstructionComponent } from "@shared/components/misc/attendee-list-file-import-instruction/attendee-list-file-import-instruction.component";
import { SnackEnum } from "@shared/enums/snack.enum";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexLegend, ApexStroke, ApexTitleSubtitle, ApexXAxis, ApexYAxis } from "ng-apexcharts";
import { catchError, map, Observable, of, Subject, switchMap, takeUntil, throwError } from "rxjs";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
};

const MIN_SEARCH_LENGTH = 3;

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit, OnDestroy, AfterViewInit {
  id: string = this.route.snapshot.paramMap.get('id');
  query: URLSearchParams = new URLSearchParams();
  event$: Observable<EventModel & { eventDate: string }>;
  destroy$: Subject<void> = new Subject<void>();

  attendeesStore: Attendee[] = [];
  attendees: Attendee[] = [];
  presentAttendees: number = 0;
  presentVipAttendees: number = 0;

  arrivalChartOptions: Partial<ChartOptions>;
  checkoutChartOptions: Partial<ChartOptions>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly eventService: EventService,
    private readonly attendeeService: AttendeeService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly snack: SnackService
  ) {
  }

  ngOnInit() {
    this.event$ = this.eventService
      .getEvent(this.id)
      .pipe(map(event => ({ ...event, eventDate: new Date(event.period.start.date).toLocaleDateString() })));

    this.eventService
      .subscribeToAttendees
      .pipe(
        takeUntil(this.destroy$),
        map(attendees => attendees.sort((a, b) => a.lastname.localeCompare(b.lastname))),
      )
      .subscribe(sortedAttendeesByLastname => {
        this.attendees = sortedAttendeesByLastname;
        this.attendeesStore = sortedAttendeesByLastname;
        this.updateCounters();
      });

    this.eventService.getAttendees(this.id, this.query);
  }

  ngAfterViewInit(): void {
    this.eventService
      .subscribeToRealtimeAttendeeUpdates(this.id)
      .pipe(
        takeUntil(this.destroy$),
        map(data => JSON.parse(data)),
      )
      .subscribe((updatedAttendee: Attendee) => {
        const attendee = this.attendees.find(attendee => attendee._id === updatedAttendee._id);
        if (attendee) {
          attendee.isPresent = updatedAttendee.isPresent;
        } else {
          this.attendees = [...this.attendees, updatedAttendee].sort((a, b) => a.lastname.localeCompare(b.lastname));
        }
        this.attendeesStore = this.attendees;
        this.updateCounters();
      });
  }

  ngOnDestroy() {
    this.eventService.removeEventSource(this.id);
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Debounce(250)
  search($event: KeyboardEvent): void {
    const searchValue = ($event.target as HTMLInputElement).value;

    this.attendees = !searchValue.length
      ? this.attendeesStore
      : this.attendeesStore.filter(searchByAttendeeName(searchValue));
  }

  goToEventList() {
    this.router.navigate(['/events']).then(() => {
    });
  }

  openUploadDialog() {
    const config: MatDialogConfig<ImportFileDialogData> = {
      data: {
        acceptedFileTypes: ACCEPTED_ATTENDEE_LIST_FILE_TYPES,
        instructionComponent: AttendeeListFileImportInstructionComponent
      },
      autoFocus: false
    };
    this.dialog
      .open<ImportFileDialogComponent>(ImportFileDialogComponent, config)
      .afterClosed()
      .subscribe((file: FormData) => {
        file && this.eventService.importAttendeeList(file, this.id)
      });
  }

  updateAttendee(attendee: Partial<Attendee>): void {
    this.updateCounters();
    this.attendeeService
      .updateAttendee(attendee)
      .pipe(switchMap(() => attendee.isPresent ? this.eventService.notifyArrival(this.id, attendee._id, false) : of([])))
      .subscribe();
  }

  filterBy(key: keyof Attendee | 'all', value?: string | boolean): void {
    if (key === 'all') {
      this.eventService.getAttendees(this.id, this.query);
      return;
    }
    this.attendees = this.attendees.filter(attendee => attendee[key] === value);
  }

  updateCounters() {
    this.presentAttendees = this.attendees.filter(attendee => attendee.isPresent).length;
    this.presentVipAttendees = this.attendees.filter(attendee => attendee.isPresent && attendee.isVip).length;
  }

  openAddAttendeeDialog() {
    this.bottomSheet
      .open(CreateOrUpdateAttendeeOverviewDialogComponent)
      .afterDismissed()
      .subscribe(this.addAttendee.bind(this));
  }

  addAttendee(attendee: Partial<Attendee> | null): void {
    if (attendee) {
      this.eventService
        .addAttendee(this.id, attendee)
        .add(() => this.snack.open('success', SnackEnum.ATTENDEE_CREATED));
    }
  }

  attendeeTrackBy(idx: number, attendee: Attendee): string {
    return attendee._id;
  }

  shareLink(): void {
    const { componentInstance } = this.dialog.open(ShareEventAccessLinkDialogComponent, { autoFocus: false });
    componentInstance
      .generateAccessLink
      .pipe(
        switchMap(selectedDuration => this.eventService.generateAccessLink(this.id, selectedDuration)),
        catchError(this.handleError.bind(this))
      )
      .subscribe(({ accessLink }: { accessLink: string }) => {
        this.snack.open('success', SnackEnum.ACCESS_LINK_GENERATED);
        componentInstance.setAccessLink = accessLink;
      });
  }

  handleError(err: any): Observable<any> {
    this.snack.open('error', SnackEnum.GENERIC_ERROR);
    return throwError(err)
  }

  openChartDialog(event: EventModel, attendees: Attendee[]): void {
    const { timestamps: arrivalTimestamps, arrivalCumulatedCounts } = mapChartArrivalData(attendees);
    const { timestamps: checkoutTimestamps, checkoutCumulatedCounts } = mapCheckoutData(event, attendees);
    const dialog = this.dialog.open(AttendeeChartsDialogComponent, {
      autoFocus: false,
      data: {
        arrivalChartOptions: makeGenericSimpleChartOptions({
          chartTitle: "Statistique des entrées",
          onHoverLabel: "Nombre d'arrivées",
          xData: arrivalCumulatedCounts,
          yData: arrivalTimestamps,
          color: '#7eeccf'
        }),
        leavingChartOptions: makeGenericSimpleChartOptions({
          chartTitle: "Statistique des sorties",
          onHoverLabel: "Nombre de présents",
          xData: checkoutCumulatedCounts,
          yData: checkoutTimestamps,
          color: `#fff161`
        })
      }
    })
    dialog.componentInstance
      .downloadPresentAttendees
      .subscribe(
        () => {
          dialog.close();
          this.downloadAttendees(event);
        }
      )
  }

  notifyArrival(attendeeId: string, withSms: boolean): void {
    this.eventService
      .notifyArrival(this.id, attendeeId, withSms)
      .subscribe(res => this.snack.open('success', SnackEnum.NOTIFICATION_SENT));
  }

  openSlackSettingDialog(event: EventModel) {
    this.dialog
      .open(SlackSettingDialogComponent, { autoFocus: false, data: { slackWebhook: event.slackWebhook } })
      .afterClosed()
      .subscribe((slackWebhook: string) => {
        if (slackWebhook) this.updateEvent({ slackWebhook });
      });
  }

  updateEvent(event: Partial<EventModel>): void {
    this.eventService
      .updateEvent(this.id, event)
      .subscribe(() => this.snack.open('success', SnackEnum.EVENT_UPDATED));
  }

  downloadAttendees(event: EventModel): void {
    const presentAttendees = this.attendees;
    const headers = ['Nom', 'Prénom', 'Entreprise', 'Heure d\'arrivée', 'VIP', 'Interlocuteur'];
    const csv = presentAttendees.map(attendee => [attendee.lastname, attendee.firstname, attendee.company, attendee.checkInTime && dayjs(attendee.checkInTime).format('DD/MM/YYYY HH:mm'), attendee.isVip, attendee.interlocutor]);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers, ...csv]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Liste invités');
    const excelBlob = XLSX.write(workbook, { bookType: 'xlsx',type: 'array' });

    const a = window.document.createElement('a');
    a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + btoa(new Uint8Array(excelBlob).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    a.download = `[${event.referenceNumber}] Liste invités - ${event.eventDate}.xlsx`;
    a.click();
  }
}
