import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "@core/guards/auth.guard";
import { LayoutComponent } from "@core/pages/layout/layout.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'events',
        loadChildren: () => import('@features/event/event.module').then(m => m.EventModule)
      },
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('@features/login/login.module').then(m => m.LoginModule)
  },
  // { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
