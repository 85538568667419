<!--
<div style="display: flex; flex-direction: column;">
    <div style="display: flex; flex-direction: row; align-items: center;">
        <mat-icon>warning</mat-icon>
        <span style="color: white; margin-left: 5px;">Attention</span>
    </div>
    <div style="display: flex; flex-direction: row;">
        <span style="color: white; margin-top: 15px;">{{ data }}</span>
    </div>
</div>

-->
<div style="display: flex; flex-direction: row;">
  <span style="color: black;">{{ data }}</span>
</div>
