<section style="height: 60vh;overflow: hidden;display: flex;flex-direction: column;">
  <div class="title-flex">
    <h2>Gestion des sorties </h2>
    <app-attendee-counter [attendeesLength]="leftAttendees" [presentAttendees]="presentAttendees - leftAttendees"></app-attendee-counter>
  </div>

  <div class="counter">
    <button (click)="decrement()" [disabled]="nbOfAttendeeToCheckout <= 1" class="decrement-btn" color="accent" mat-raised-button>-</button>
    <button (click)="validateCheckout()" class="btn" color="accent" mat-raised-button>
      <mat-icon class="remove-icon">person_remove</mat-icon>
      <p style="font-size: var(--font-size-xxl);"> {{ nbOfAttendeeToCheckout }} </p>
    </button>
    <button (click)="increment()" class="increment-btn" color="accent" mat-raised-button>+</button>
  </div>
  <p><i>{{nbOfAttendeeToCheckout}} {{nbOfAttendeeToCheckout > 1 ? 'personnes sont prêtes' : 'personne est prête'}} à partir, cliquez pour valider.</i></p>
  <h3 style="display:flex; align-items:center; gap: .5rem; margin: .5rem 0;">
    <mat-icon>av_timer</mat-icon>
    Dernières sorties
  </h3>
  <div style="overflow-y: auto">
    <ng-container *ngFor="let checkout of checkouts">
      <p style="display: flex;align-items: center; gap: 1rem; margin: .5rem 0 .5rem 0">
        <mat-icon>{{checkout.nb === 1 ? 'person_outlined' : 'group_outlined'}}</mat-icon>
        <b>{{ checkout.nb }}</b>{{ checkout.nb === 1 ? 'personne' : 'personnes'}} <b>{{ checkout.at }}</b>
      </p>
    </ng-container>
  </div>
</section>
