<!--<app-online-bar [online]="!isOnline"></app-online-bar>-->
<mat-toolbar color="primary">
  <div class="menu-wrapper">
    {{ currentUser?.firstname }}
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu>
      <button mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <a (click)="logout()">Déconnexion</a>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<router-outlet></router-outlet>
