<h1 mat-dialog-title>Partager l'accès</h1>
<div mat-dialog-content>

  <p>Choisissez la durée de validité de l'accès :</p>

  <form [formGroup]="shareForm" (ngSubmit)="generateAccessLink.next(shareForm.value)">
    <mat-radio-group formControlName="duration" class="radio-group" color="primary">
      <mat-radio-button *ngFor="let duration of durations" [value]="duration.value">{{ duration.label }}</mat-radio-button>
    </mat-radio-group>
    <button mat-flat-button class="btn" color="accent" [disabled]="!shareForm.valid">Partager</button>
  </form>

  <button *ngIf="accessLink" [cdkCopyToClipboard]="accessLink" class="btn copy" color="primary" mat-stroked-button>
    <mat-icon>content_copy</mat-icon>
    Copier le lien d'accès
  </button>

  <div mat-dialog-actions class="align-end">
    <button class="btn" color="primary" mat-stroked-button mat-dialog-close="true">Fermer</button>
  </div>
</div>
