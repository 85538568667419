<section style="width: 90%; padding: 2rem 0; margin: auto;">
  <mat-form-field style="width: 100%;">
    <mat-label>Rechercher...</mat-label>
    <input matInput (keyup)="search($event)" minlength="3" placeholder="ex: 2022_0231" #input>
  </mat-form-field>

  <ng-container *ngIf="dataSource | async as data">
    <table mat-table [dataSource]="data" class="mat-elevation-z4">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <b>{{ column.header }}</b>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row[column.columnDef] }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr (click)="navigateTo(row)" *matRowDef="let row; columns: displayedColumns;" class="pointer" mat-row></tr>
    </table>
  </ng-container>
</section>
