import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LoginService } from '@features/login/services/login.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  currentUser = this.loginService.userValue;

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login'])
  }
}
