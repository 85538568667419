import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChartOptions } from "@features/event/pages/event-view/event-view.component";

@Component({
  selector: 'app-attendee-charts-dialog',
  templateUrl: './attendee-charts-dialog.component.html',
  styleUrls: ['./attendee-charts-dialog.component.scss']
})
export class AttendeeChartsDialogComponent {

  @Output() downloadPresentAttendees: EventEmitter<void> = new EventEmitter<void>();

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { arrivalChartOptions: Partial<ChartOptions>; leavingChartOptions: Partial<ChartOptions> }
  ) {
  }
}
