<h1 mat-dialog-title>Importer un fichier</h1>
<div mat-dialog-content>
  <input type="file" [accept]="data.acceptedFileTypes" class="file-input" (change)="onFileSelected($event)" #file hidden>

  <ng-template [cdkPortalOutlet]="injectableComponent"></ng-template>

  <div style="display: flex; align-items: center; justify-content:center; gap: 1rem; flex-direction: column">
    <button mat-flat-button color="accent" class="btn" (click)="file.click()">
      <mat-icon>attach_file</mat-icon> {{ fileName || "Aucun fichier choisi." }}
    </button>
    <a (click)="downloadImportTemplate()" style="cursor: pointer"><u>Télécharger le modèle</u></a>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button class="btn" color="warn" mat-stroked-button mat-dialog-close>Annuler</button>
  <button class="btn" color="primary" mat-stroked-button [disabled]="!fileName" (click)="close()">Importer ce fichier</button>
</div>

