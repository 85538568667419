<ng-container *ngIf="event$ | async as event">
  <mat-toolbar style="height: 35px; font-size: 14px; display: flex; flex-direction: row; align-items: center; gap: 2rem">
    <button (click)="goToEventList()" class="back-btn" color="primary" mat-stroked-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div style="overflow:auto;font-size: 12px;">{{ event.company }} - {{ event.eventDate }}</div>
  </mat-toolbar>


  <section class="container">
    <app-action-toolbar
      (openUploadDialog)="openUploadDialog()"
      (openChartDialog)="openChartDialog(event, attendees)"
      (openShareAccessLinkDialog)="shareLink()"
      (exportAttendeeListDialog)="downloadAttendees(event)"
      (openSlackSettingDialog)="openSlackSettingDialog(event)">
    </app-action-toolbar>

    <mat-form-field class="search-bar mat-form-field-no-hint">
      <mat-label>Rechercher un invité</mat-label>
      <input (keyup)="search($event)" matInput minlength="3" placeholder="ex: Jean" type="text">
      <button (click)="$event.stopPropagation(); openAddAttendeeDialog()" class="suffix-icon-btn" mat-icon-button matSuffix>
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </mat-form-field>
    <div style="margin: .5rem 0; display:flex;justify-content: space-between; align-items: center">
      <div style="display: flex; align-items: center; gap:.5rem">
        <button (click)="filterBy('all')" disableRippl mat-stroked-button style="height: 20px; font-size: 12px; border-radius: 12px; letter-spacing: 0">Tous</button>
        <button (click)="filterBy('isVip', true)" disableRipple mat-stroked-button style="height: 20px; font-size: 12px; border-radius: 12px; letter-spacing: 0">VIP</button>
        <button (click)="filterBy('isPresent', false)" disableRipple mat-stroked-button style="height: 20px; font-size: 12px; border-radius: 12px; letter-spacing: 0">Absent</button>
      </div>

      <app-attendee-counter [attendeesLength]="attendees.length" [presentAttendees]="presentAttendees" [presentVipAttendees]="presentVipAttendees"></app-attendee-counter>
    </div>
    <mat-divider style="margin: 0.5rem 0 1rem 0;"></mat-divider>

    <ng-container *ngIf="attendees.length; else noAttendees">
      <div class="overflow-scroll">
        <ul class="attendees-list">
          <app-attendee
            *ngFor="let attendee of attendees; trackBy: attendeeTrackBy"
            (notifyArrival)="notifyArrival($event, true)"
            (updateAttendee)="updateAttendee($event)"
            [attendee]="attendee">
          </app-attendee>
        </ul>
      </div>
    </ng-container>
    <ng-template #noAttendees>
      <div class="no-attendee">Merci d'importer une liste d'invités 📑</div>
    </ng-template>

  </section>
</ng-container>
