import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { SnackService } from "@core/services/snack.service";
import { BehaviorSubject, map, Observable, Subscription, switchMap, tap } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // @ts-ignore
  #apiUrl = environment.baseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly snack: SnackService
  ) {
  }

  get userValue() {
    return JSON.parse(localStorage.getItem('eec_user'));
  }

  set user(user: any) {
    localStorage.setItem('eec_user', JSON.stringify(user));
  }


  loginWithCharlie(email: string, password: string): Observable<any> {
    return this.http
      .post(`${this.#apiUrl}/auth/login`, { email, password })
      .pipe(
        tap(res => {
          this.user = res;
        })
      );
  }

  getUserInfo(): Observable<any> {
    return this.http.get(`${this.#apiUrl}/auth/me`);
  }

  logout(): Subscription {
    localStorage.removeItem('eec_user');
    return this.http.get(`${this.#apiUrl}/auth/logout`).subscribe();
  }
}
