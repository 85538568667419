import { NgOptimizedImage } from "@angular/common";
import { Component } from '@angular/core';

@Component({
  selector: 'app-attendee-list-file-import-instruction',
  templateUrl: './attendee-list-file-import-instruction.component.html',
  styleUrls: ['./attendee-list-file-import-instruction.component.scss']
})
export class AttendeeListFileImportInstructionComponent {

}
