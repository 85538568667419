import { ClipboardModule } from "@angular/cdk/clipboard";
import { PortalModule } from "@angular/cdk/portal";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { InputComponent } from "@shared/components/mat-form/input/input.component";
import { ButtonComponent } from '@shared/components/mat-form/button/button.component';
import { SnackErrorComponent, SnackInfoComponent, SnackLoadingComponent, SnackSuccessComponent, SnackWarningComponent } from "@shared/components/snacks";
import { NgApexchartsModule } from "ng-apexcharts";
import { PresentAttendeesPipe } from './pipes/present-attendees.pipe';
import { CreateAttendeeDialogComponent } from './components/dialogs/create-attendee-dialog/create-attendee-dialog.component';
import { ImportFileDialogComponent } from './components/dialogs/import-file-dialog/import-file-dialog.component';
import { AttendeeListFileImportInstructionComponent } from './components/misc/attendee-list-file-import-instruction/attendee-list-file-import-instruction.component';
import { CreateOrUpdateAttendeeOverviewDialogComponent } from './components/dialogs/create-or-update-attendee-overview-dialog/create-or-update-attendee-overview-dialog.component';
import { OnlineBarComponent } from './components/online-bar/online-bar.component';
import { ShareEventAccessLinkDialogComponent } from './components/dialogs/share-event-access-link-dialog/share-event-access-link-dialog.component';
import { SlackSettingDialogComponent } from './components/dialogs/slack-setting-dialog/slack-setting-dialog.component';
import { AttendeeChartsDialogComponent } from './components/dialogs/attendee-charts-dialog/attendee-charts-dialog.component';


@NgModule({
  declarations: [
    SnackErrorComponent,
    SnackInfoComponent,
    SnackLoadingComponent,
    SnackSuccessComponent,
    SnackWarningComponent,
    InputComponent,
    ButtonComponent,
    PresentAttendeesPipe,
    CreateAttendeeDialogComponent,
    ImportFileDialogComponent,
    AttendeeListFileImportInstructionComponent,
    CreateOrUpdateAttendeeOverviewDialogComponent,
    OnlineBarComponent,
    ShareEventAccessLinkDialogComponent,
    SlackSettingDialogComponent,
    AttendeeChartsDialogComponent
  ],
  exports: [
    InputComponent,
    ButtonComponent,
    PresentAttendeesPipe,
    OnlineBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    PortalModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    ClipboardModule,
    NgApexchartsModule
  ]
})
export class SharedModule {
}
