import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventListViewComponent } from "@features/event/pages/event-list-view/event-list-view.component";
import { EventStaffViewComponent } from "@features/event/pages/event-staff-view/event-staff-view.component";
import { EventViewComponent } from "@features/event/pages/event-view/event-view.component";

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: EventListViewComponent },
      { path: ':id', component: EventViewComponent }
    ]
  },
  {
    path: 'staff/:token',
    data: { roles: ['staff'] },
    component: EventStaffViewComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule {
}
