import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Debounce } from "@core/decorators";
import { OnlineService } from "@core/services/online.service";
import { SnackService } from "@core/services/snack.service";
import { AttendeeLeavingCounterComponent } from "@features/attendee/components/attendee-leaving-counter/attendee-leaving-counter.component";
import { Attendee } from "@features/attendee/models/attendee.model";
import { AttendeeService } from "@features/attendee/services/attendee.service";
import { BaseEventView, Event as EventModel } from "@features/event/models/event.model";

import { EventService } from "@features/event/services/event.service";
import { CreateOrUpdateAttendeeOverviewDialogComponent } from "@shared/components/dialogs/create-or-update-attendee-overview-dialog/create-or-update-attendee-overview-dialog.component";
import { SnackEnum } from "@shared/enums/snack.enum";
import { catchError, finalize, map, Observable, of, Subject, switchMap, takeUntil, throwError } from "rxjs";

@Component({
  selector: 'app-event-staff-view',
  templateUrl: './event-staff-view.component.html',
  styleUrls: ['./../event-view/event-view.component.scss']
})
export class EventStaffViewComponent implements OnInit, AfterViewInit, BaseEventView {

  id: string;
  token: string = this.route.snapshot.paramMap.get('token');
  query: URLSearchParams = new URLSearchParams();
  event$: Observable<EventModel & { eventDate: string }>;
  destroy$: Subject<void> = new Subject<void>();

  attendees: Attendee[] = [];
  attendeesStore: Attendee[] = [];
  presentAttendees: number = 0;
  presentVipAttendees: number = 0;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly eventService: EventService,
    private readonly attendeeService: AttendeeService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly snack: SnackService,
    private readonly onlineService: OnlineService
  ) {
  }

  ngOnInit() {
    this.event$ = this.eventService
      .isTokenAlive(this.token)
      .pipe(
        takeUntil(this.destroy$),
        map((res: { id: string }) => {
          this.id = res.id;
          localStorage.setItem('eec_staff_id', res.id);
          return res;
        }),
        switchMap((res: { id: string }) => {
          return this.eventService.getEventForStaff(res.id, this.token)
        }),
        map((event: EventModel) => ({ ...event, eventDate: new Date(event.period.start.date).toLocaleDateString() })),
        finalize(() => {
          this.eventService
            .getAttendeesForStaff(this.id, this.token, this.query)
            .add(() => this.listenToAttendeesUpdates())
        }),
        catchError((err) => {
          this.snack.open('error', SnackEnum.TOKEN_EXPIRED);
          return throwError(err);
        }),
      )

    this.eventService
      .subscribeToAttendees
      .pipe(
        takeUntil(this.destroy$),
        map(attendees => attendees.sort((a, b) => a.lastname.localeCompare(b.lastname)))
      )
      .subscribe(attendees => {
        this.attendees = attendees;
        this.attendeesStore = attendees;
        this.setCounters();
      });
  }

  ngAfterViewInit() {
    // throw new Error('Method not implemented.');
  }

  listenToAttendeesUpdates() {
    this.eventService
      .subscribeToRealtimeAttendeeUpdates(this.id)
      .pipe(
        takeUntil(this.destroy$),
        map(data => JSON.parse(data))
      )
      .subscribe((updatedAttendee: Attendee) => {
        const attendee = this.attendees.find(attendee => attendee._id === updatedAttendee._id);
        if (attendee) {
          attendee.isPresent = updatedAttendee.isPresent;
        } else {
          this.attendees.push(updatedAttendee);
          this.attendees = this.attendees.sort((a, b) => a.lastname.localeCompare(b.lastname));
        }
        this.attendeesStore = this.attendees;
        this.setCounters();
      });
  }

  ngOnDestroy() {
    this.eventService.removeEventSource(this.id);
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-ignore
  @Debounce(250)
  search($event: KeyboardEvent): void {
    const searchValue = ($event.target as HTMLInputElement).value;

    this.attendees = !searchValue.length
      ? this.attendeesStore
      : this.attendeesStore.filter(this.searchByAttendeeName(searchValue));
  }

  searchByAttendeeName(searchValue: string): (attendee) => boolean {
    return attendee => attendee.firstname.toLowerCase().includes(searchValue.toLowerCase()) || attendee.lastname.toLowerCase().includes(searchValue.toLowerCase())
  }

  setCounters() {
    this.presentAttendees = this.attendees.filter(attendee => attendee.isPresent).length;
    this.presentVipAttendees = this.attendees.filter(attendee => attendee.isPresent && attendee.isVip).length;
  }

  updateAttendee(attendee: Partial<Attendee>): void {
    this.setCounters();

    this.attendeeService
      .updateAttendeeForStaff(attendee, this.token)
      .pipe(switchMap(() => attendee.isPresent ? this.eventService.notifyArrival(this.id, attendee._id, false, this.token) : of([])))
      .subscribe();
  }

  filterBy(key: keyof Attendee | 'all', value?: string | boolean): void {
    if (key === 'all') {
      this.attendees = this.attendeesStore;
      return;
    }
    this.attendees = this.attendeesStore.filter(attendee => attendee[key] === value);
  }

  openAddAttendeeDialog() {
    this.bottomSheet
      .open(CreateOrUpdateAttendeeOverviewDialogComponent)
      .afterDismissed()
      .subscribe((attendee: Attendee) => this.addAttendee(attendee));
  }

  addAttendee(attendee: Partial<Attendee> | null): void {
    if (attendee) {
      this.eventService
        .addAttendeeForStaff(this.id, this.token, attendee)
        .add(() => this.snack.open('success', SnackEnum.ATTENDEE_CREATED));
    }
  }

  notifyArrival(attendeeId: string, withSms): void {
    this.eventService
      .notifyArrival(this.id, attendeeId, withSms, this.token)
      .subscribe(res => this.snack.open('success', SnackEnum.NOTIFICATION_SENT));
  }


  attendeeTrackBy(idx: number, attendee: Attendee): string {
    return attendee._id;
  }

  handleError(err: any): Observable<any> {
    this.snack.open('error', SnackEnum.GENERIC_ERROR);
    return throwError(err)
  }

  openLeavingBottomSheet() {
    this.bottomSheet.open(AttendeeLeavingCounterComponent, { data: { token: this.token, eventId: this.id } });
  }
}
