import { Attendee } from "@features/attendee/models/attendee.model";
import { Observable, Subject } from "rxjs";

export interface Event {
  _id: string;
  company: string;
  referenceNumber: string;
  period: Period;
  // mapped data
  eventDate?: string;
  slackWebhook?: string;
  checkouts: { at: number, nb: number }[]
}

type Period = Record<'start' | 'end', TimePrecision>;

type TimePrecision = {
  hour: string;
  date: string;
}

export enum AccessLinkDuration {
  ONE_DAY = '1d',
  TWO_DAYS = '2d'
}

export interface BaseEventView {

  id: string;
  token: string;
  query: URLSearchParams;
  event$: Observable<Event & { eventDate: string }>;
  destroy$: Subject<void>;

  attendees: Attendee[];
  attendeesStore: Attendee[];
  presentAttendees: number;
  presentVipAttendees: number;
}
