import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { SseService } from "@core/services/sse.service";
import { Attendee, AttendeeLeavingCounterData } from "@features/attendee/models/attendee.model";
import { AccessLinkDuration, Event } from "@features/event/models/event.model";
import { Observable, of, Subject, Subscription, tap } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  attendees$: Subject<Attendee[]> = new Subject<Attendee[]>();

  // @ts-ignore
  get subscribeToAttendees(): Observable<Attendee[]> {
    return this.attendees$.asObservable();
  }

  constructor(
    private readonly http: HttpClient,
    private readonly sseService: SseService
  ) {
  }

  getEvents(searchString?: string): Observable<Event[]> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('s', searchString);
    return this.http.get<Event[]>(`${environment.baseUrl}/event${searchString ? `?${urlSearchParams.toString()}` : ''}`);
  }

  getEvent(id: string): Observable<Event> {
    return this.http.get<Event>(`${environment.baseUrl}/event/${id}`);
  }

  getEventForStaff(id: string, token: string): Observable<Event> {
    return this.http.get<Event>(`${environment.baseUrl}/event/staff/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
  }

  importAttendeeList(file: FormData, eventId: string): Subscription {
    return this.http
      .post(`${environment.baseUrl}/attendee/import/${eventId}`, file)
      .subscribe((attendees: Attendee[]) => this.attendees$.next(attendees));
  }

  subscribeToRealtimeAttendeeUpdates(eventId: string, token?: string): Observable<string> {
    return this.sseService.getMessagesFrom(eventId, token);
  }

  getAttendees(id: string, query: URLSearchParams): Subscription {
    return this.http
      .get<Attendee[]>(`${environment.baseUrl}/event/${id}/attendees?${query.toString()}`)
      .subscribe((attendees: Attendee[]) => this.attendees$.next(attendees));
  }

  getAttendeesForStaff(id: string, token: string, query: URLSearchParams): Subscription {
    return this.http
      .get<Attendee[]>(`${environment.baseUrl}/event/staff/${id}/attendees?${query.toString()}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .subscribe((attendees: Attendee[]) => this.attendees$.next(attendees));
  }

  deleteAttendee(attendeeId: string, id: string): Subscription {
    return this.http
      .delete(`${environment.baseUrl}/attendee/${attendeeId}/${id}`)
      .subscribe((attendees: Attendee[]) => this.attendees$.next(attendees));
  }

  addAttendee(id: string, attendee: Partial<Attendee>): Subscription {
    return this.http.post(`${environment.baseUrl}/attendee/${id}`, attendee).subscribe();
    // .subscribe((attendees: Attendee[]) => this.attendees$.next(attendees));
  }

  addAttendeeForStaff(id: string, token: string, attendee: Partial<Attendee>): Subscription {
    return this.http
      .post(`${environment.baseUrl}/attendee/staff/${id}`, attendee, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .subscribe((attendees: Attendee[]) => this.attendees$.next(attendees));
  }

  generateAccessLink(id: string, payload: { duration: AccessLinkDuration }): Observable<{ accessLink: string }> {
    return this.http.post<{ accessLink: string }>(`${environment.baseUrl}/event/${id}/link`, payload);
  }

  removeEventSource(id: string): void {
    this.sseService.close(id);
  }

  isTokenAlive(token: string): Observable<{ id: string, role: string }> {
    return this.http.get<{ id: string, role: string }>(`${environment.baseUrl}/event/staff/token/${token}`, { headers: { 'Authorization': `Bearer ${token}` } });
  }

  checkoutAttendee(eventId: string, token: string, payload: { nb: number }): Observable<AttendeeLeavingCounterData> {
    return this.http.post<AttendeeLeavingCounterData>(`${environment.baseUrl}/event/staff/${eventId}/checkout`, payload, { headers: { 'Authorization': `Bearer ${token}` } });
  }

  getCheckouts(eventId: string, token: string): Observable<AttendeeLeavingCounterData> {
    return this.http.get<AttendeeLeavingCounterData>(`${environment.baseUrl}/event/staff/${eventId}/checkouts`, { headers: { 'Authorization': `Bearer ${token}` } });
  }

  notifyArrival(id: string, attendeeId: string, withSms: boolean, token?: string): Observable<Attendee> {
    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
    return this.http.post<Attendee>(`${environment.baseUrl}/attendee/${attendeeId}/notify`, { withSms }, { headers });
  }

  updateEvent(id: string, event): Observable<any> {
    return this.http.patch(`${environment.baseUrl}/event/${id}`, event);
  }
}
