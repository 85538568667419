import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Debounce } from "@core/decorators";
import type { Event as EventModel } from "@features/event/models/event.model";
import { EventService } from "@features/event/services/event.service";
import { eventMapper } from "@features/event/utils/event.util";
import { map, Observable, of } from "rxjs";

@Component({
  selector: 'app-event-list-view',
  templateUrl: './event-list-view.component.html',
  styleUrls: ['./event-list-view.component.scss']
})
export class EventListViewComponent implements OnInit {

  columns = [
    { columnDef: 'company', header: 'Client', /*cell: (element: Event) => `${element.referenceNumber}`*/ },
    { columnDef: 'referenceNumber', header: 'N° dossier', /*cell: (element: Event) => `${element.referenceNumber}`*/ },
    { columnDef: 'eventDate', header: 'Date de l\'évènement', /*cell: (element: Event) => `${element.referenceNumber}`*/ },
  ];
  displayedColumns = this.columns.map(c => c.columnDef);
  dataSource: Observable<EventModel[] & any>;

  constructor(
    private readonly eventService: EventService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.dataSource = this.eventService.getEvents().pipe(map(eventMapper));
  }

  navigateTo({ _id: eventId }: EventModel) {
    this.router.navigate([eventId], { relativeTo: this.route }).then(() => {});
  }

  @Debounce(250)
  search($event: KeyboardEvent): void {
    const searchValue = ($event.target as HTMLInputElement).value;

    this.dataSource = searchValue.length >= 3
      ? this.eventService.getEvents(searchValue).pipe(map(eventMapper))
      : of([]);
  }
}
