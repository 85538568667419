import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { AttendeeLeavingCounterData, Checkout } from "@features/attendee/models/attendee.model";
import { EventService } from '@features/event/services/event.service';
import { map, tap } from "rxjs";


@Component({
  selector: 'app-attendee-leaving-counter',
  templateUrl: './attendee-leaving-counter.component.html',
  styleUrls: ['./attendee-leaving-counter.component.scss']
})
export class AttendeeLeavingCounterComponent implements OnInit {

  total: number = 1;
  checkouts: Checkout[];
  presentAttendees: number;
  leftAttendees: number;
  nbOfAttendeeToCheckout: number = 1;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { eventId: string, token: string },
    private readonly bottomSheetRef: MatBottomSheetRef<AttendeeLeavingCounterComponent>,
    private readonly eventService: EventService
  ) {
  }

  ngOnInit(): void {
    this.eventService
      .getCheckouts(this.data.eventId, this.data.token)
      .pipe(tap(this.formatCheckout.bind(this)))
      .subscribe()
  }

  validateCheckout(): void {
    if (this.total) {
      this.total--;
      this.eventService
        .checkoutAttendee(this.data.eventId, this.data.token, { nb: this.nbOfAttendeeToCheckout })
        .pipe(tap(this.formatCheckout.bind(this)))
        .subscribe()
    }
  }

  formatCheckout(res: AttendeeLeavingCounterData): void {
    this.total = res.nbOfAttendees;
    this.checkouts = res.checkouts;
    this.nbOfAttendeeToCheckout = 1;
    this.presentAttendees = res.nbOfAttendees;
    this.leftAttendees = res.checkouts.reduce((acc, c) => acc + c.nb, 0);
  }

  increment() {
    this.nbOfAttendeeToCheckout++;
  }

  decrement() {
    if (this.nbOfAttendeeToCheckout > 0) this.nbOfAttendeeToCheckout--;
  }
}
