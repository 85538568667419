import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-info',
  templateUrl: './snack-info.component.html',
  styleUrls: ['./snack-info.component.scss'],
})
export class SnackInfoComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackInfoComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  public dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
