<div mat-dialog-content>
  <h2 mat-dialog-title>Ajouter un invité</h2>

  <form [formGroup]="form">
    <mat-form-field>
      <input formControlName="lastname" matInput placeholder="Nom" required>
    </mat-form-field>
    <mat-form-field>
      <input formControlName="firstname" matInput placeholder="Prénom" required>
    </mat-form-field>
    <mat-form-field>
      <input formControlName="company" matInput placeholder="Entreprise">
    </mat-form-field>
  </form>

  <mat-dialog-actions style="display: flex; gap:1rem" align="end">
    <button class="btn" mat-stroked-button color="warn" (click)="dismiss(null)">Annuler</button>
    <button class="btn" mat-stroked-button color="primary" (click)="dismiss(form.value)">Valider</button>
  </mat-dialog-actions>
</div>
