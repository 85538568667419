import { Component, EventEmitter, Input, OnInit, Output, TrackByFunction } from '@angular/core';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Attendee } from "@features/attendee/models/attendee.model";

@Component({
  selector: 'app-attendee',
  templateUrl: './attendee.component.html',
  styleUrls: ['./attendee.component.scss']
})
export class AttendeeComponent implements OnInit {

  @Input() attendee: Attendee;
  @Output() updateAttendee: EventEmitter<Partial<Attendee>> = new EventEmitter<Partial<Attendee>>();
  @Output() notifyArrival: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  check({ checked }: MatSlideToggleChange): void {
    this.attendee.isPresent = checked;
    this.updateAttendee.emit({ _id: this.attendee._id, isPresent: checked });
  }

  notify(attendee: Attendee) {
    this.notifyArrival.emit(attendee._id);
  }
}
