import { Attendee } from "@features/attendee/models/attendee.model";
import { Event as EventModel } from "@features/event/models/event.model";
import { ChartOptions } from "@features/event/pages/event-view/event-view.component";
import dayjs from 'dayjs';

import 'dayjs/locale/fr';

dayjs.locale('fr');

const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;

type HexadecimalColor = `#${string}`;

export function makeGenericSimpleChartOptions({ onHoverLabel, chartTitle, xData, yData, color = '#ffd83d' }: {
  onHoverLabel: string;
  chartTitle: string;
  xData: number[];
  yData: string[];
  color?: HexadecimalColor
}): Partial<ChartOptions> {
  return {
    series: [{ name: onHoverLabel, color, data: xData }],
    chart: {
      redrawOnParentResize: false,
      redrawOnWindowResize: true,
      fontFamily: 'Be Vietnam Pro',
      type: 'area',
      height: 350,
      zoom: { enabled: false }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    title: { text: chartTitle, align: 'left' },
    labels: yData,
    xaxis: {
      type: 'category',
      categories: yData,
      labels: {
        formatter: timestamp => dayjs(+timestamp).format('D MMMM hh:mm')
      }
    },
    legend: { horizontalAlign: 'left' }
  }
}

export function mapChartArrivalData(attendees: Attendee[]): { arrivalCumulatedCounts: number[], timestamps: string[] } {
  const arrivedAttendees = getArrivalTimes(attendees)
  const { intervalTimestamps, values } = groupByTimestampInterval(arrivedAttendees)(FIFTEEN_MINUTES_IN_MS);
  return { arrivalCumulatedCounts: values.map(cumulativeSum), timestamps: intervalTimestamps }
}

export function mapCheckoutData({ checkouts }: EventModel, attendees: Attendee[]): { checkoutCumulatedCounts: number[], timestamps: string[] } {
  const timestamps = getCheckoutTimes(checkouts);
  const nbs = getPresentAttendeesCount(attendees);
  const { values, intervalTimestamps } = groupByTimestampInterval(timestamps)(FIFTEEN_MINUTES_IN_MS);
  return { checkoutCumulatedCounts: values.map(cumulativeSoustract(nbs)), timestamps: intervalTimestamps }
}

export function roundToNearestInterval(timestamp: number, intervalInMs: number): number {
  return Math.round(new Date(timestamp).getTime() / intervalInMs) * intervalInMs;
}

export function groupByTimestampInterval(timestamps: number[]) {
  return function (intervalInMs: number) {
    const map = new Map<string, number>()
    const groupedTimestamps = timestamps.reduce((acc, timestamp) => {
      const roundedTimestamp = roundToNearestInterval(timestamp, intervalInMs);
      const key = roundedTimestamp.toString();
      return incrementMap(acc, key);
    }, map);

    return {
      values: Array.from(groupedTimestamps.values()),
      intervalTimestamps: Array.from(groupedTimestamps.keys())
    };
  }
}

const cumulativeSum = ((cumulator: number) => (value: number) => cumulator += value)(0);

const cumulativeSoustract = (sum: number) => (value: number) => sum -= value;

function getArrivalTimes(attendees: Attendee[]): number[] {
  return attendees
    .filter(attendee => attendee.checkInTime)
    .map(attendee => attendee.checkInTime)
    .sort();
}

function getCheckoutTimes(checkouts: EventModel['checkouts']): number[] {
  return checkouts.map(checkout => checkout.at).sort();
}

function getPresentAttendeesCount(attendees: Attendee[]): number {
  return attendees.filter(attendee => attendee.isPresent).length;
}

function incrementMap(map: Map<string, number>, key: string): Map<string, number> {
  const value = map.get(key);
  map.set(key, value ? value + 1 : 1);
  return map;
}
