import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-slack-setting-dialog',
  templateUrl: './slack-setting-dialog.component.html',
  styleUrls: ['./slack-setting-dialog.component.scss']
})
export class SlackSettingDialogComponent implements OnInit {

  slackWebhook: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { slackWebhook: string }
  ) {
  }

  ngOnInit(): void {
    this.slackWebhook = this.data.slackWebhook;
  }
}
