import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackErrorComponent, SnackInfoComponent, SnackLoadingComponent, SnackSuccessComponent, SnackWarningComponent } from 'src/app/shared/components/snacks';

type verticalPosition = 'top' | 'bottom';
type horizontalPosition = 'start' | 'center' | 'end' | 'left' | 'right';
type status = 'success' | 'warning' | 'info' | 'error' | 'loading';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(private snack: MatSnackBar) {
  }

  open(
    type: status,
    message: string,
    posX: horizontalPosition = 'right',
    posY: verticalPosition = 'top'
  ): void {
    const params = {
      duration: 3000,
      data: message,
      horizontalPosition: posX,
      verticalPosition: posY,
    };
    switch (type) {
      case 'success':
        this.snack.openFromComponent(SnackSuccessComponent, {
          ...params,
          panelClass: ['snack--success', 'snack-container']
        });
        break;
      case 'warning':
        this.snack.openFromComponent(SnackWarningComponent, {
          ...params,
          panelClass: ['snack--warning', 'snack-container']
        });
        break;
      case 'info':
        this.snack.openFromComponent(SnackInfoComponent, {
          ...params,
          panelClass: ['snack--info', 'snack-container']
        });
        break;
      case 'error':
        this.snack.openFromComponent(SnackErrorComponent, {
          ...params,
          panelClass: ['snack--error', 'snack-container']
        });
        break;
      case 'loading':
        this.snack.openFromComponent(SnackLoadingComponent, {
          ...params,
          panelClass: ['snack--loading', 'snack-container']
        });
        break;
      default:
        break;
    }
  }
}
