import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-toolbar',
  templateUrl: './action-toolbar.component.html',
  styleUrls: ['./action-toolbar.component.scss']
})
export class ActionToolbarComponent implements OnInit {

  @Output() uploadAttendeeList: any[];

  @Output() openUploadDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() openShareAccessLinkDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSlackSettingDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() openChartDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportAttendeeListDialog: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
  }

  ngOnInit() {
  }
}

