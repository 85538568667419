import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ImportFileDialogData { acceptedFileTypes: string[], instructionComponent: ComponentType<any> }



@Component({
  selector: 'app-import-file-dialog',
  templateUrl: './import-file-dialog.component.html',
  styleUrls: ['./import-file-dialog.component.scss']
})
export class ImportFileDialogComponent implements OnInit {

  error: string;
  fileName: string;
  formData: FormData = new FormData();
  injectableComponent: ComponentPortal<ComponentType<any>> | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { acceptedFileTypes: string[], instructionComponent: ComponentType<any> },
    private ref: MatDialogRef<ImportFileDialogComponent>
  ) {
  }

  ngOnInit() {
    this.injectableComponent = new ComponentPortal(this.data.instructionComponent);
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files.item(0);
    const extension = file.name.split('.').pop();
    if (this.data.acceptedFileTypes.includes(`.${extension}`) && file) {
      this.fileName = file.name;
      this.formData.append('file', file);
    }
  }

  close(): void {
    this.ref.close(this.formData);
  }

  downloadImportTemplate() {
    const a = window.document.createElement('a');
    const content = 'Prénom,Nom,Entreprise,VIP,Nom interlocuteur,Téléphone\n';
    a.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(content)}`);
    a.setAttribute('download', `import_outil_emargement.csv`);
    // a.setAttribute('target', '_blank');
    a.click();
  }
}
