import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from "@angular/router";
import { SnackService } from "@core/services/snack.service";
import { SnackEnum } from "@shared/enums/snack.enum";
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private readonly snack: SnackService,
    private readonly router: Router
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse | any): Observable<any> {
    // A client-side or network error occurred. Handle it accordingly.
    if (error instanceof HttpErrorResponse) {
      switch ((<HttpErrorResponse>error).status) {
        case 401:
        case 403:
          this.router
            .navigate(['/login'])
            .then((navigated: boolean) => navigated && this.snack.open('error', SnackEnum.CANNOT_ACCESS_RESOURCE));
          break;
        default:
          if (error.error.message) this.snack.open('error', error.error.message);
          break;
      }
    }
    return throwError(error);
  }
}
