import { Event as EventModel } from "@features/event/models/event.model";


export const ACCEPTED_ATTENDEE_LIST_FILE_TYPES = ['.xls', '.xlsx', '.ods', '.csv'];

/**
 * Search attendees by lastname or firstname property
 * @param searchValue
 * @returns {(attendee) => boolean}
 */
export function searchByAttendeeName(searchValue: string): (attendee: { lastname: string, firstname: string }) => boolean {
  return attendee => strIncludes(attendee.firstname, searchValue) || strIncludes(attendee.lastname, searchValue);
}

function strIncludes<T>(string: string, value: T): boolean {
  return string.toLowerCase().includes(value.toString().toLowerCase())
}


export function eventMapper(events: EventModel[]): { referenceNumber: string; limitDate: string; company: string; _id: string; eventDate: string }[] {
  return events.map((event: EventModel) => ({
    _id: event._id,
    company: event.company,
    eventDate: new Date(event.period.start.date).toLocaleDateString(),
    limitDate: new Date(event.period.start.date).toLocaleDateString(),
    referenceNumber: event.referenceNumber
  }))
}

