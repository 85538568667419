import { Component, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccessLinkDuration } from "@features/event/models/event.model";
import { Subject } from "rxjs";

@Component({
  selector: 'app-share-event-access-link-dialog',
  templateUrl: './share-event-access-link-dialog.component.html',
  styleUrls: ['./share-event-access-link-dialog.component.scss']
})
export class ShareEventAccessLinkDialogComponent {

  shareForm: FormGroup = new FormGroup({
    duration: new FormControl(null, [Validators.required]),
  });
  durations: { value: AccessLinkDuration, label: string }[] = [
    { value: AccessLinkDuration.ONE_DAY, label: '1 jour' },
    { value: AccessLinkDuration.TWO_DAYS, label: '2 jours' },
  ];
  accessLink: string;
  generateAccessLink: Subject<{ duration: AccessLinkDuration }> = new Subject<{ duration: AccessLinkDuration }>();
  selectedChoice: string;

  // @ts-ignore
  set setAccessLink(link: string) {
    this.accessLink = link;
  }
}
