<h1 mat-dialog-title>Statistiques entrées/sorties</h1>
<div mat-dialog-content class="content">
  <a (click)="downloadPresentAttendees.emit()" style="cursor: pointer"><u>Télécharger la liste des présents</u></a>
  <ng-container *ngTemplateOutlet="chartTemplate; context:{ chart: data.arrivalChartOptions }"></ng-container>
  <ng-container *ngTemplateOutlet="chartTemplate; context:{ chart: data.leavingChartOptions }"></ng-container>
</div>
<div mat-dialog-actions align="end">
  <button class="btn" color="primary" mat-dialog-close="true" mat-stroked-button>Fermer</button>
</div>

<ng-template #chartTemplate let-chart="chart">
  <apx-chart
    *ngIf="chart?.series"
    [series]="chart.series"
    [chart]="chart.chart"
    [xaxis]="chart.xaxis"
    [stroke]="chart.stroke"
    [dataLabels]="chart.dataLabels"
    [yaxis]="chart.yaxis"
    [labels]="chart.labels"
    [legend]="chart.legend"
    [title]="chart.title"
    [subtitle]="chart.subtitle"
  ></apx-chart>
</ng-template>
