import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AttendeeCounterComponent } from "@features/attendee/components/attendee-counter/attendee-counter.component";
import { AttendeeComponent } from "@features/attendee/components/attendee/attendee.component";

import { AttendeeRoutingModule } from './attendee-routing.module';
import { AttendeeLeavingCounterComponent } from './components/attendee-leaving-counter/attendee-leaving-counter.component';


@NgModule({
  declarations: [
    AttendeeComponent,
    AttendeeCounterComponent,
    AttendeeLeavingCounterComponent
  ],
  exports: [
    AttendeeComponent,
    AttendeeCounterComponent
  ],
  imports: [
    CommonModule,
    AttendeeRoutingModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDividerModule
  ]
})
export class AttendeeModule {
}
