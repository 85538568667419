<li [class.is-present]="attendee.isPresent" class="attendee">
  <div class="attendee-wrapper">
    <div class="attendee--name ellipsis">{{ attendee.lastname | uppercase }} {{ attendee.firstname | titlecase }}</div>
    <div class="tags">
      <div class="chip pink" *ngIf="attendee.isVip">VIP</div>
      <mat-divider vertical *ngIf="attendee.isVip && attendee.company "></mat-divider>
      <div class="chip yellow" *ngIf="attendee.company">{{ attendee.company | uppercase }}</div>
    </div>
  </div>


  <div style="gap:1rem; display: flex; align-items: center">
    <button
      *ngIf="attendee.isPresent && attendee.notifyPhone"
      (click)="notify(attendee)"
      [disabled]="attendee.isNotified"
      color="accent"
      mat-flat-button
      style="font-size: var(--font-size-xs); border-radius: var(--radius-xl); height:25px"
    >
      <mat-icon style="margin: auto;display: flex;align-items: center;justify-content: center;">send</mat-icon>
    </button>
    <mat-slide-toggle [checked]="attendee.isPresent" (change)="check($event)"></mat-slide-toggle>
  </div>
</li>
