import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Attendee } from "@features/attendee/models/attendee.model";

@Component({
  selector: 'app-attendee-counter',
  templateUrl: './attendee-counter.component.html',
  styleUrls: ['./attendee-counter.component.scss']
})
export class AttendeeCounterComponent {

  @Input() attendeesLength: number;
  @Input() presentAttendees: number = 0;
  @Input() presentVipAttendees: number = 0;

}
